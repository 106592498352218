import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './components/Login';
import ConfirmEmail from './components/ConfirmEmail';
import lazyWithRetry from './Routing/LazyWithRetry';

const ApplyReview = React.lazy(() => import('./components/ApplyReview'));
const Wizard = React.lazy(() => import('./components/Wizard'));
const LostPassword = React.lazy(() => import('./components/LostPassword'));
const ResetPassword = React.lazy(() =>
  import('./components/LostPassword/ResetPassword')
);
const AppRouter = React.lazy(() => import('./Routing/AppRouter'));
const ContractLess = React.lazy(() => import('./components/Contractless'));
const OwnerRouter = lazyWithRetry(() => import('./Routing/OwnerRouter'));

function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/lost_password" component={LostPassword} />
        <Route path="/reset_password/:token" component={ResetPassword} />
        <Route path="/login" component={Login} />
        <Route path="/sign_up" component={ContractLess} />
        <Route path="/confirm_email/:token" component={ConfirmEmail} />
        <Route
          path="/apply_review/:house_id/:locale/:booking_nr"
          component={ApplyReview}
        />
        <Route path="/wizard" component={Wizard} />
        <Route path="/owner*" exact component={OwnerRouter} />
        <Route path="/*" exact component={AppRouter} />
      </Switch>
    </Router>
  );
}

export default Routes;
