import React from 'react';
// import { Redirect } from "react-router-dom";
import gql from 'graphql-tag';

import store from '../../redux/store';
import client from '../../apollo/client';
import { loginUser } from '../../redux/actions/userActions';
import { Form, Input, Button, message } from 'antd';
import { withTranslation } from 'react-i18next';
import { KeyOutlined, MailOutlined } from '@ant-design/icons';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.emailInput = React.createRef();
    this.passwordInput = React.createRef();
    this.state = {
      email: '',
      password: '',
      data: null,
      errors: null,
    };
  }

  onSubmit = e => {
    const { email, password } = this.state;

    client
      .mutate({
        mutation: LOGIN,
        variables: {
          email,
          password,
        },
      })
      .then(resp => {
        const { data } = resp;
        localStorage.setItem('owner', data.loginUser.owner_id);
        localStorage.setItem('enviroment', data.loginUser.userType);
        this.setState({ data: data });
        store.dispatch(loginUser(data.loginUser));
      })
      .catch(errors => {
        errors.graphQLErrors.map(err => {
          return message.error(err.message);
        });
      });
  };

  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render = () => {
    const { email, password } = this.state;
    const { t } = this.props;

    return (
      <Form onFinish={this.onSubmit} layout="vertical">
        <Form.Item label={t('app.email')}>
          <Input
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={this.onChange}
            ref={this.emailInput}
            placeholder="Email"
            required={true}
          />
        </Form.Item>
        <Form.Item label={t('app.password')}>
          <Input
            prefix={<KeyOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            autoComplete="current-password"
            name="password"
            value={password}
            onChange={this.onChange}
            ref={this.passwordInput}
            placeholder={t('app.password')}
            required={true}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            id="app_login"
            htmlType="submit"
            style={{ width: '100%' }}
          >
            {t('app.login')}
          </Button>
        </Form.Item>
      </Form>
    );
  };
}

export default withTranslation()(LoginForm);

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    loginUser(password: $password, email: $email) {
      email
      first_name
      last_name
      owner_id
      userType
    }
  }
`;
