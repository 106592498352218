import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import './index.css';
import './i18n';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import nl_NL from 'antd/lib/locale-provider/nl_NL';
import de_DE from 'antd/lib/locale-provider/de_DE';
import en_GB from 'antd/lib/locale-provider/en_GB';
import { Spin, ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/de';

const setLocale = localStorage.getItem('mivillaLanguage');
const locale = setLocale ? setLocale : 'nl';

const localeSetting = {
  nl: nl_NL,
  de: de_DE,
  en: en_GB,
};

moment.locale(locale);

require('dotenv').config();

if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2644c70ddda84090b40797b9126f872d@sentry.io/1496921',
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spin />}>
      <ConfigProvider locale={localeSetting[locale]}>
        <App />
      </ConfigProvider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
