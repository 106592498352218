import React from 'react';
import Login from './Login';
import Wrapper from '../Authenticate/Wrapper';

const Index = () => (
  <Wrapper>
    <Login />
  </Wrapper>
)
export default Index;
