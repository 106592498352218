import React from 'react';
import { ApolloProvider } from 'react-apollo';
import './App.css';
import Routes from './Routes';
import client from './apollo/client';
import { ThemeProvider } from 'styled-components';
import CacheBuster from './CacheBuster';

const Theme = {
  primaryColor: process.env.REACT_APP_COLOR,
};

let stage = <div></div>;
if (process.env.REACT_APP_STAGING === true) {
  stage = (
    <div
      style={{
        position: 'fixed',
        right: 20,
        bottom: 20,
        color: 'red',
        fontSize: 18,
        fontWeight: 'bold',
      }}
    >
      STAGING ENVIROMENT
    </div>
  );
}

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }

        return (
          <ApolloProvider client={client}>
            <ThemeProvider theme={Theme}>
              <div className="App">
                <Routes />
                {stage}
              </div>
            </ThemeProvider>
          </ApolloProvider>
        );
      }}
    </CacheBuster>
  );
}

export default App;
