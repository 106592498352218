import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import client from '../../apollo/client';
import store from '../../redux/store';
import { loginUser } from '../../redux/actions/userActions';

import { Spin } from 'antd';


class ConfirmEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    const { match } = this.props;
    client
      .mutate({
        mutation: CONFIRM_QUERY,
        variables: { token: match.params.token },
      })
      .then(({ data }) => {
        store.dispatch(loginUser(data.confirmUser));

        localStorage.setItem('owner', data.confirmUser.owner_id);
        localStorage.removeItem('wizardStep');
        localStorage.removeItem('wizardData');

        this.setState({ loading: false, user: data.confirmUser });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, user } = this.state;
    if (loading) {
      return <Spin size="large" />;
    } else {
      if (user && user.email) {
        return <Redirect to="/wizard" />;
      } else {
        return <Redirect to="/wizard" />;
      }
    }
  }
}

const CONFIRM_QUERY = gql`
  mutation ConfirmQuery($token: String!) {
    confirmUser(confirmation_token: $token) {
      id
      email
      is_trial
    }
  }
`;

ConfirmEmail.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ConfirmEmail;
