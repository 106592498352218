import React, { Component } from 'react';
import gql from 'graphql-tag';

import client from '../../apollo/client';
import store from '../../redux/store';
import { loginUser } from '../../redux/actions/userActions';

import { Spin } from 'antd';

const TOKEN_LOGIN = gql`
  mutation LoginWithToken {
    tokenLogin {
      id
      email
      first_name
      last_name
      is_super_admin
      owner_id
      locales
      userType
      rights
      owner {
        id
        booking_fields {
          id
          field_type
          label
          options
        }
      }
    }
  }
`;

class Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount = () => {
    client
      .mutate({
        mutation: TOKEN_LOGIN,
      })
      .then(({ data }) => {
        store.dispatch(loginUser(data.tokenLogin));

        localStorage.setItem('owner', data.tokenLogin.owner_id);

        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { children } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <Spin size="large" />;
    } else {
      return <div>{children}</div>;
    }
  }
}

export default Wrapper;
