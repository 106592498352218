import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import fetch from 'isomorphic-fetch';
import history from '../history';
import Cookies from 'universal-cookie';

import config from './config';
// import store from "../redux/store";

const useLocalStorage = true;

const UNAUTHORIZED = 'Forbidden';
const FORBIDDEN = 'Unauthorized';
// const NOT_ALLOWED = "Not allowed";

const cookies = new Cookies();

const httpLink = createHttpLink({
  uri: config.networkInterface,
  credentials: 'include',
});

const authMiddlewareLink = setContext(() => {
  const headers = {
    headers: {
      'access-token': cookies.get('access-token') || null,
      uid: cookies.get('uid') || null,
      client: cookies.get('client') || null,
      expiry: cookies.get('expiry') || null,
      'token-type': cookies.get('token-type') || null,
    },
  };
  return headers;
});

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const keys = ['access-token', 'uid', 'client', 'expiry', 'token-type'];
      keys.forEach(key => {
        let token = headers.get(key);
        if (token) {
          cookies.set(key, token, { path: '/', secure: true, maxAge: 259200 });
        }
      });
    }

    return response;
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors.filter(e => e).length > 0) {
    graphQLErrors.map(({ message = '', status = 200 }) => {
      if (UNAUTHORIZED === message || status === 401) {
        console.warn(`You've attempted to access ${UNAUTHORIZED} section`);
        if (
          history &&
          history.location &&
          history.location.pathname !== '/login'
        ) {
          history.push('/login');
        }
      }
      if (FORBIDDEN === message || status === 403) {
        console.warn(`You've attempted a ${FORBIDDEN} action`);
        history.push(`/error-page/403`);
      }
      return null;
    });
  }
  if (networkError && networkError.statusCode === 401) {
    // eslint-disable-next-line
    // history.push("/login");
    console.warn(UNAUTHORIZED);
  }
  if (networkError && networkError.statusCode === 400) {
    // eslint-disable-next-line
    // history.push("/login");
    console.warn(UNAUTHORIZED);
  }
  if (networkError && networkError.statusCode === 403) {
    // Do something
    console.warn(FORBIDDEN);
  }
  if (networkError && networkError.statusCode >= 500) {
    // eslint-disable-next-line
    console.warn('SERVER ERROR');
    // history.push(`/error-page/${networkError.statusCode}`);
  }
});

let links = [errorLink, httpLink];

if (useLocalStorage) {
  links = [errorLink, afterwareLink, authMiddlewareLink, httpLink];
}

const link = ApolloLink.from(links);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
  query: {
    fetchPolicy: 'network-only',
  },
};

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  fetch,
  defaultOptions,
});

export default client;
