import { combineReducers } from 'redux';
import userReducer from './userReducer';
import helpReducer from './helpReducer';

const appReducer = combineReducers({
  user: userReducer,
  helpPage: helpReducer,
});

export default function(state, action) {
  if (action.type === 'SIGNOUT_USER') {
    state = undefined; // eslint-disable-line no-param-reassign
  }
  return appReducer(state, action);
}
