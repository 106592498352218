import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import Form from './Form';
import { connect } from 'react-redux';
import { FormHolder, Container, OwnerSection } from './Login.css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MiVillaLogo } from '../../icons/logo-mivilla.svg';

import image from './image.jpg';
import { Button } from 'antd';

const mapStateToProps = props => {
  const { user } = props;
  return user;
};

function Login({ user }) {
  const { t } = useTranslation();
  if (user && user.email) {
    return <Redirect to="/" noThrow />;
  } else {
    return (
      <Container>
        <div className="login-img">
          <img src={image} alt="Logo" />
          <div className="login-img-text">
            <MiVillaLogo width={500} height={400} />
          </div>
        </div>
        <FormHolder>
          <div className="login">
            <div className="login-form">
              <h2>{t('app.login_for_landlord_and_owners')}</h2>
              <Form />
              <Link to="/lost_password" id="lost_password_link">
                {t('app.forgot_your_password?')}
              </Link>
            </div>
            <OwnerSection>
              <div className="guest">
                <h3>{t('app.login.login_as_guest')}</h3>
                <a href="https://api.mivilla.nl/mybooking">
                  <Button type="primary" size="small">
                    {t('app.login.go_to_guest_enviroment')}
                  </Button>
                </a>
              </div>
            </OwnerSection>
          </div>
        </FormHolder>
      </Container>
    );
  }
}

Login.propTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps)(Login);
